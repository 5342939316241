#root {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  transition: all ease-in-out .35s;
}

.App-logo {
  height: 40vmin;
}

.App-header {
     transition: all ease-in-out 2.0s;
  background-color: #01114b;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 60px;
}

.App-header a {
     color: #fff;
     text-decoration: none;
}

.App-link {
  color: #09d3ac;
}

.clb-footer {
     margin-top: 60px;
  transition: all ease-in-out 2.0s;
  background: #01114b;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



.header-icons {
  margin-left: 8px;
}

.clb-settings.revealed {
  background: #09d3ac;
  padding: 30px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-left: 30px;
  margin-right: 0;
  margin-top: 30px;
}

.single-setting-area h3 {
     margin-top: 24px;
     margin-bottom: 4px;
}

.single-setting-area.question-types {
     min-width: 180px;
}

.single-setting-area.difficulty {
     min-width: 140px;
}

.clb-footer p.inspired {
     padding-top: 12px;
     font-size: 24px;
     font-weight: 300;
     margin-bottom: 12px;
}

.version {
     margin-top: 0px;
     padding-bottom: 20px;
     font-size: 14px;
}

.clb-footer a {
     color: #fff;
     text-decoration: none;
}

.clb-footer a:hover {
     text-decoration: underline;
}

.clb-quiz-body-area {
     margin-bottom: 60px;
}

h2.quiz-active-message {
     font-weight: 300;
     font-size: 3em;
     margin-top: 40px;
     margin-bottom: 6px;
}

.type-of-question {
     font-size: 12px;
     color: #09d3ac;
}

.math-problem-numbers {
     margin: 30px;
     font-weight: 300;
     font-size: 4em;
}



#math-answer-form {
     font-size: 2em;
     font-weight: 300;
}

#math-answer-form input[type=number]
{
	background: transparent;
	border: none;
	border-bottom: 2px dashed #09d3ac;
	width: 150px;
	outline: none;
	padding: 0px 0px 0px 0px;
     font-size: 1em;
     font-weight: 300;
     margin-left: 8px;
}

#name-form {
     margin: 30px;
     font-size: 18px;
}

#name-form input {
     margin-left: 8px;
}

.clb-welcome-area p {
     font-weight: 300;
    font-size: 2em;
    margin-bottom: 30px;
}

button, input[type=submit] {
     transition: all ease-in-out 2.0s;
     background: #76B3FA;
     border: 0;
     border-radius: 8px;
     padding: 18px 30px;
     color: #fff;
     text-decoration: none;
     font-size: 16px;
     margin: 0 15px;
     transition: all ease-in-out .2s;
     text-transform: uppercase;
}

.clb-settings button, .clb-settings input[type=submit] {
     border: 0;
     border-radius: 5px;
     padding: 8px 14px;
     color: #fff;
     text-decoration: none;
     font-size: 13px;
     margin: 8px;
     transition: all ease-in-out .2s;
     text-transform: none;
}

button:hover, .clb-settings button:hover, input[type=submit]:hover {
     cursor: pointer;
     background: #01114b;
}

.clb-settings button {
     transition: all ease-in-out .25s;
     background: #021b79;
     border: 2px solid #021b79;
     font-weight: 700;
}

button.type-of-problem.active, button.difficulty.active, button.show-summary.active {
     background: #76B3FA;
}

.clb-settings {
     transition: all 2.0s ease-in-out;
}

.clb-settings.revealed {
     /* display: flex;
     justify-content: space-between; */
}

.single-setting-area.start-over {
     /* height: 60%; */
}

.single-setting-area.start-over button {
    background: red;
    width: 100%;
    margin-top: 24px;
}

#hide-settings {
     width: 100%;
    margin-top: 0;
}

.single-setting-area.start-over button:hover {
     background: #000;
}

.summary-correct {
     margin-left: 6px;
     color: green;
}

.summary-wrong {
     margin-left: 6px;
     color: red;
}

.summary-area {
     background: #f5f5f5;
     padding: 30px 30px 60px;
}

.current-score {
     font-weight: 300;
     font-size: 2em;
}

.clb-settings.hidden {
     position: fixed;
     right: -18px;
}

#start-quiz {
     margin-bottom: 30px;
}

button#start-quiz, button#start-sprints {
     padding: 30px 40px;
     font-size: 30px;
     background: linear-gradient(270deg,#01114b,#09d3ac);
    background-size: 400% 400%;
    -webkit-backface-visibility: hidden !important;
    backface-visibility: hidden !important;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-animation: buttonBackgroundFader 8s ease infinite;
    -moz-animation: buttonBackgroundFader 8s ease infinite;
    animation: buttonBackgroundFader 8s ease infinite;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

button#start-sprints {
     background: linear-gradient(270deg,#09d3ac, #01114b);
     background-size: 400% 400%;
    -webkit-backface-visibility: hidden !important;
    backface-visibility: hidden !important;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-animation: buttonBackgroundFader 8s ease infinite;
    -moz-animation: buttonBackgroundFader 8s ease infinite;
    animation: buttonBackgroundFader 8s ease infinite;
}

@-webkit-keyframes buttonBackgroundFader {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes buttonBackgroundFader {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes buttonBackgroundFader {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

.summary {
     display: grid;
     grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
     grid-gap: 20px;
     /* justify-items: stretch;
     justify-self: center; */
     /* align-self: center; */
     /* align-items: center; */
     justify-content: space-around;
}

.blue .single-answer {
     display: inline-block;
}

.single-answer-result {
     color: #333;
     padding: 12px;
     padding-bottom: 0;
     background: #fff;
}

.single-answer .question-number {
     border-top-left-radius: 12px;
     border-top-right-radius: 12px;
     color: #fff;
     padding: 6px 0;
}

.single-answer-user.time-elapsed {
     background: #fff;
     font-size: 12px;
     color: #999;
     padding-bottom: 12px;
     border-bottom-left-radius: 12px;
     border-bottom-right-radius: 12px;
}

.single-answer-user {
     font-size: 13px;
}

#choose-colors-select, #question-type-select, #difficulty-select {
     text-align: left;
}



.off-canvas-menu p {
     padding: 40px;
     background: #fff;
}

.score-area {
     color: #999;
     margin-bottom: 30px;
}

h2.current-score {
     color: #555;
     margin-bottom: 0;
}

h3.sprint-complete-message {
     font-size: 30px;
     font-weight: 300;
}

.sprint-ending-area {
     padding-top: 30px;
}

.settings-container-sprints {
     width: 80%;
     margin-left: auto;
     margin-right: auto;
     padding: 30px;
     border-radius: 12px;
     background: #f5f5f5;
     margin-bottom: 30px;
}

.clb-sprint-settings {
     display: flex;
     justify-content: space-around;
}

.clb-sprint-settings h3 {
     margin-top: 0;
}

#clb-lets-go {
     font-size: 30px;
}

.clb-sprint-settings .single-setting-area.start-over button {
    width: 100%;
    height: 100%;
    margin-top: 0;
}


.clb-quiz-alert {
     padding: 14px 20px;
     border-radius: 12px;
     background: #f5f5f5;
     display: inline-block;
     font-weight: 400;
     line-height: 1;
     font-size: 24px;
}

.clb-quiz-alert .what-next {
     line-height: 1.4;
     font-size: 14px;
}

.what-next {
     margin-top: 12px;
}

.timer-area h2 {
     font-weight: 300;
}

.timer-area.red h2 {
     font-weight: 700;
}

.timer-area.red {
     font-weight: 700;
     color: red;
}

/* ------ COLORS: BLUE ---------------- */
.blue .single-answer .question-number {
     transition: all ease-in-out 2.0s;
     background: #021b79;
}

.blue .clb-quiz-alert {
     background: #09d3ac;
     transition: all ease-in-out 2.0s;
}

.blue .settings-container-sprints {
     background: #09d3ac;
}


/* ------ COLORS: PINK ---------------- */
.App.pink .App-header {
     transition: all ease-in-out 2.0s;
     background-color: #b91d73;
}

.pink .single-answer .question-number {
     transition: all ease-in-out 2.0s;
     background: #b91d73;
}

.App.pink .clb-footer {
  transition: all ease-in-out 2.0s;
  background: #6e1145;
}

.App.pink button, .App.pink input[type=submit] {
     transition: all ease-in-out 2.0s;
     background: #b91d73;
}

.App.pink button:hover, .App.pink .clb-settings button:hover, .App.pink input[type=submit]:hover {
     cursor: pointer;
     background: #6e1145;
     transition: all ease-in-out .25s;
}

.App.pink .clb-settings.revealed,  .pink .clb-quiz-alert, .pink .settings-container-sprints {
  background: #f5bddc;
  transition: all ease-in-out 2.0s;
}

.App.pink .clb-settings button {
     transition: all ease-in-out 2.0s;
     background: #b91d73;
     border: 2px solid #b91d73;
     font-weight: 700;
}

.App.pink .type-of-question {
     color: #b91d73;
}

.App.pink #math-answer-form input[type=number]
{
	border-bottom: 2px dashed #b91d73;
     transition: all ease-in-out 2.0s;
}




/* ------ COLORS: ORANGE ---------------- */
.App.orange .App-header {
     transition: all ease-in-out 2.0s;
     background-color: #F7971E;
}

.App.orange .clb-footer {
  transition: all ease-in-out 2.0s;
  background: #de7f08;
}

.App.orange button, .App.orange input[type=submit] {
     transition: all ease-in-out 2.0s;
     background: #F7971E;
}

.orange .single-answer .question-number {
     transition: all ease-in-out 2.0s;
     background: #F7971E;
}

.App.orange button:hover, .App.orange .clb-settings button:hover, .App.orange input[type=submit]:hover {
     cursor: pointer;
     background: #de7f08;
     transition: all ease-in-out .25s;
}

.App.orange .clb-settings button {
     transition: all ease-in-out 2.0s;
     background: #F7971E;
     border: 2px solid #F7971E;
     font-weight: 700;
}

.App.orange #math-answer-form input[type=number]
{
	border-bottom: 2px dashed #F7971E;
     transition: all ease-in-out 2.0s;
}

.App.orange .clb-settings.revealed, .orange .clb-quiz-alert, .orange .settings-container-sprints {
  background: #fcddb5;
  transition: all ease-in-out 2.0s;
}

.App.orange .single-setting-area.start-over button {
     background: red;
}

.App.orange .type-of-question {
     color: #F7971E;
}


/* ------ COLORS: GREEN ---------------- */
.App.green .App-header {
     transition: all ease-in-out 2.0s;
     background-color: #3CA55C;
}

.App.green .clb-footer {
  transition: all ease-in-out 2.0s;
  background: #225d34;
}

.App.green button, .App.green input[type=submit] {
     transition: all ease-in-out 2.0s;
     background: #3CA55C;
}

.green .single-answer .question-number {
     transition: all ease-in-out 2.0s;
     background: #3CA55C;
}

.App.green button:hover, .App.green .clb-settings button:hover, .App.green input[type=submit]:hover {
     cursor: pointer;
     background: #225d34;
     transition: all ease-in-out .25s;
}

.App.green #math-answer-form input[type=number]
{
	border-bottom: 2px dashed #3CA55C;
     transition: all ease-in-out 2.0s;
}

.App.green .clb-settings button {
     transition: all ease-in-out 2.0s;
     background: #3CA55C;
     border: 2px solid #3CA55C;
     font-weight: 700;
}

.App.green .single-setting-area.start-over button {
     background: #76B3FA;
}

.App.green .clb-settings.revealed, .green .clb-quiz-alert, .green .settings-container-sprints {
  background: #c7ebd2;
  transition: all ease-in-out 2.0s;
}

.App.green .type-of-question {
     color: #3CA55C;
}

#name-form input[type=submit] {
     padding: 8px 14px;
}

.sprint-ending-area button {
     margin-bottom: 8px;
}

/* Animate the Racecar
---------------------------------------------------------------------------------------------------- */

.header-icons {
     font-size: 48px;
     color: #09d3ac;
       position: absolute;
       animation-duration: 6s;
       animation-timing-function: ease-in;
       animation-name: slidein;
       animation-fill-mode: forwards;
}

@keyframes slidein {
  from {
    left: 0;
  }

  to {
    /* margin-right: 0;
    width: 100%; */
    left: 100%;
  }

}


.App-header h1 {
     animation-duration: 7s;
     animation-timing-function: ease-in-out;
     animation-name: revealLetters;
     animation-fill-mode: forwards;
}

@keyframes revealLetters {
  from {
    color: #01114b;
    font-size: 2px;
  }

  to {
    color: #fff;
    font-size: 48px;
  }
}


/* Media Queries
---------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1300px) {

     .summary {
          grid-template-columns: 1fr 1fr 1fr 1fr;
     }

}

@media only screen and (max-width: 1024px) {

     .summary {
          grid-template-columns: 1fr 1fr 1fr;
     }

}

@media only screen and (max-width: 800px) {

     .summary {
          grid-template-columns: 1fr 1fr;
     }

     .clb-sprint-settings {
          display: block;
     }

     .clb-sprint-settings h3 {
         margin-top: 24px;
     }

}
